import React from "react"
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from "@fortawesome/fontawesome-svg-core";
import Header from "../components/header"
import { graphql } from 'gatsby'
import {  MyLayout, PostLink, StyledPostLink, PostLinkHeader} from '../components/common'

config.autoAddCss = false;

const Layout = ({ data }) => {
    const edges = data.allMarkdownRemark.edges;

    return (<div>
        <Header />
        <MyLayout>

                    {edges.map((e, i) => {
                        const frontmatter = e.node.frontmatter
                        return (<PostLink key={i} frontmatter={frontmatter} />)
                    })}
                            <PostLinkHeader>
        <StyledPostLink to="/allpages">All Posts</StyledPostLink>
        <StyledPostLink to="/about">About</StyledPostLink>
        </PostLinkHeader>
        </MyLayout>


    </div>)
}

export const query = graphql`query HomepageQuery {
    allMarkdownRemark(
        sort: {order: DESC, fields: [frontmatter___date]} limit:3
    ) {
      edges {
        node{
          frontmatter { 
            title
            path
            excerpt
            date
        }
        }
      }
    }
  }`


export default Layout

