import React from "react"
import {Helmet} from "react-helmet"

const MyHelmet = ({description}) => {
    return (
        <Helmet htmlAttributes={{"lang": "en"}} >
            <title>Iain Lumsden</title>

            <meta name="description" content={description} />
        </Helmet>
    )
}

export default MyHelmet