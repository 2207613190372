import React from "react"
import {graphql, StaticQuery} from "gatsby"
import {PostLinkHeader, HeaderWrapper, Title, StyledDiscription, IconStyles} from '../common'
import {SafeAnchor} from '../anchor'
import MyHelmet from "../helmet"
import {
    faGithub,
    faInstagram,
    faTwitter
} from '@fortawesome/free-brands-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'


const TitleHeader = ({data}) => {
    const title = data.site.siteMetadata.title
    const description = data.site.siteMetadata.description
    return (

        <HeaderWrapper>
            <MyHelmet description={description}/>
            <Title>{title}</Title>
            <StyledDiscription>{description}</StyledDiscription>
            <PostLinkHeader>
                <SafeAnchor href="https://github.com/Malachiain/" title="github" >
                    <FontAwesomeIcon icon={faGithub} size="lg" style={IconStyles}/>
                </SafeAnchor>
                <SafeAnchor href="https://www.instagram.com/iainlumsden_nz/" title="instagram" >
                    <FontAwesomeIcon icon={faInstagram} size="lg"   style={IconStyles}/>
                </SafeAnchor>
                <SafeAnchor href="https://twitter.com/_iainlumsden/" title="twitter" >
                    <FontAwesomeIcon icon={faTwitter} size="lg"  style={IconStyles}/>
                </SafeAnchor>
            </PostLinkHeader>
        </HeaderWrapper>
    )
}

const Header = () => {
    return (
        <StaticQuery
            query={graphql`
        query{
            site {
                siteMetadata {
                    title
                    description
                }
            }
        }
        `}
            render={data => <TitleHeader data={data}/>}
        />
    )
}

export default Header